import React from 'react';

interface QuestionParams {
    title: string;
    input?: string;
    handleSubmitAnswer(input: number): void;
}

function InputQuestion({ title, input, handleSubmitAnswer }: QuestionParams) {
    const [text, setText] = React.useState<number>();

    const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        if (text !== undefined) {
            handleSubmitAnswer(text);
        }
    }

    return (
        <div className="quiz-content">
            <h1 className="quiz-question-title">
                {title}
            </h1>

            <form>
                <div className="quiz-input-container">
                    <input className="quiz-input" min={20} max={250} type="number" placeholder={input} value={text} onChange={(e) => setText(+e.target.value)} />
                </div>
                <div className="quiz-button-container">
                    <button className="quiz-button" onClick={handleSubmit}>Следующий вопрос</button>
                </div>
            </form>

        </div>
    );
}

export default InputQuestion;
