import React, { ReactElement, ReactChild } from 'react';
import { Animate } from "react-move";
import { easeQuadInOut } from "d3-ease";

type Props = {
    duration: number;
    valueStart: number;
    valueEnd: number;
    children(value: number): ReactElement<any>;
    onEnd(): void;
};

type State = {
    isAnimated: boolean;
};


class AnimatedProgressProvider extends React.Component<Props, State> {
    private timeoutId: number | undefined = undefined;

    state = {
        isAnimated: false
    };

    static defaultProps = {
        valueStart: 0
    };

    componentDidMount() {
        this.setState({
            isAnimated: !this.state.isAnimated
        });

        this.timeoutId = window.setTimeout(this.props.onEnd, this.props.duration * 1000);
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeoutId);
    }

    render() {
        return (
            <Animate
                start={() => ({
                    value: this.props.valueStart
                })}
                update={() => ({
                    value: [
                        this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
                    ],
                    timing: {
                        duration: this.props.duration * 1000,
                        ease: easeQuadInOut
                    }
                })}
            >
                {({ value }) => this.props.children(value)}
            </Animate>
        );
    }
}

export default AnimatedProgressProvider;
