import React from 'react';

interface Answer {
    title: string;
    description: string | null;
}

interface QuestionParams {
    answers: Answer[];
    title: string;
    selectedAnswer: number | null;
    handleSelectAnswer(selectedAnswer: number): void;
}

function SelectQuestion({ title, answers, selectedAnswer, handleSelectAnswer }: QuestionParams) {
    return (
        <div className="quiz-content">
            <h1 className="quiz-question-title">
                {title}
            </h1>

            <section className="quiz-question-answers">
                {answers.map((answer, index) =>
                    <div className={selectedAnswer == index ? "quiz-question-answer quiz-question-answer--Selected" : "quiz-question-answer"} key={index} onClick={() => handleSelectAnswer(index)}>
                        <div className="quiz-question-answer-content">
                            <div className="quiz-question-answer-header">{answer.title}</div>
                            <div className="quiz-question-answer-description">{answer.description}</div>
                        </div>
                        <span>
                            <input className="quiz-question-answer-checkbox" readOnly type="checkbox" checked={selectedAnswer == index} /><label />
                        </span>
                    </div>
                )}
            </section>
        </div>
    );
}

export default SelectQuestion;
