import React from 'react';
import './App.css';
import Quiz from './components/quiz'
import { SelectQuestion } from './components/questions'

function App() {
  const [selectedAnswer, setSelectedAnswer] = React.useState<number | null>(null);
  const [nextQuestion, setNextQuestion] = React.useState<boolean>(false);

  const currentQuestion = {
    title: 'Ваш пол?',
    answers: [{ title: 'Мужской', description: null }, { title: 'Женский', description: null }],
    selectedAnswer: selectedAnswer
  }

  const handleSelectAnswer = (selectedAnswer: number) => {
    setSelectedAnswer(selectedAnswer);

    clearTimeout((window as any).timeoutId);
    (window as any).timeoutId = setTimeout(() => setNextQuestion(true), 1000);
  }

  return (
    <div className="app">
      <header className="app-header">
        <img src="logo.svg" className="app-logo" alt="Shapex" />
        <span>Лаборатория<br /> стройности</span>
      </header>

      {(selectedAnswer === null || !nextQuestion) ?
        <>
          <h3>Комплексная программа снижения веса</h3>
          <p>Узнайте что нужно именно вам
            <br />
            ответив на пару вопросов
          </p>
          <SelectQuestion {...currentQuestion} handleSelectAnswer={handleSelectAnswer} />
        </>
        :
        <Quiz gender={selectedAnswer ? 'female' : 'male'} goBack={() => {
          setSelectedAnswer(null);
          setNextQuestion(false)
        }} />}
    </div>
  );
}

export default App;
