import React from 'react';
import { SelectQuestion, InputQuestion } from '../questions'
import AnimatedProgressProvider from '../progressbar'

import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


interface Answer {
    title: string;
    description: string | null;
}

interface Question {
    answers: Answer[];
    title: string;
    selectedAnswer: number | null;
    input?: string | undefined;
}

type Props = {
    gender: string;
    goBack(): void;
};

type State = {
    questions: Question[];
    currentQuestion: number;
    showResults: boolean;
};

class Quiz extends React.Component<Props, State> {
    private timeoutId: number | undefined = undefined;

    state: State;
    constructor(props: Props) {
        super(props);

        this.state = {
            questions: [
                {
                    title: 'Ваш возраст?',
                    answers: [{ title: 'до 25', description: null }, { title: '25 - 40', description: null }, { title: '40 - 55', description: null }, { title: '55+', description: null },],
                    selectedAnswer: null
                },
                {
                    title: 'Ваш вес?',
                    answers: [],
                    input: 'Ваш вес в килограммах',
                    selectedAnswer: null
                },
                {
                    title: 'Ваш уровень физической активности?',
                    answers: [{ title: 'Низкий', description: 'дом/офис' }, { title: 'Умеренный', description: 'недолгие прогулки' }, { title: 'Активный', description: '2-3 тренировки в неделю' }, { title: '"Пламенный мотор"', description: '(О спорт, ты - жизнь!)' }],
                    selectedAnswer: null
                },
                {
                    title: 'Сколько раз вы уже пробовали снизить вес?',
                    answers: [{ title: 'Это первый раз', description: null }, { title: 'Было несколько попыток', description: null }, { title: 'Много раз, но долгосрочный эффект не получен', description: null }, { title: 'Перепробовано все, что только можно, но безрезультатно', description: null },],
                    selectedAnswer: null
                }],
            currentQuestion: 0,
            showResults: false
        };

    }

    componentDidUpdate() {
        if (this.state.showResults) {
            const selectedAnswer = this.state.questions[3].selectedAnswer;

            if (selectedAnswer === null) {
                this.handleGoBack();
                return
            }

            const video = this.props.gender === 'male' ? (window as any).videoResults.male[selectedAnswer] : (window as any).videoResults.female[selectedAnswer];

            (window as any).playVideo(video);
        }
    }

    handleSelectAnswer = (i: number, selectedAnswer: number) => {
        window.clearTimeout(this.timeoutId);
        this.timeoutId = window.setTimeout(() => {
            this.setState((prevState) => {
                return { currentQuestion: prevState.currentQuestion + 1 };
            })
        }
            , 1000);

        this.setState(prevState => {
            let newState = prevState;

            newState.questions[i].selectedAnswer = selectedAnswer

            return { questions: newState.questions };
        });
    }

    handleInputAnswer = (i: number, selectedAnswer: number) => {
        this.setState(prevState => {
            let newState = prevState;

            newState.questions[i].selectedAnswer = selectedAnswer

            return { questions: newState.questions, currentQuestion: prevState.currentQuestion + 1 };
        });
    }

    handleGoBack = () => {
        const currentQuestionId = this.state.currentQuestion;

        if (currentQuestionId > 0) {
            this.setState(prevState => {
                return { currentQuestion: prevState.currentQuestion - 1 };
            });
        } else {
            this.props.goBack();
        }
    }

    render() {
        let currentQuestionId = this.state.currentQuestion;

        if (currentQuestionId >= this.state.questions.length) {
            return (
                <div className="quiz-results">
                    {this.state.showResults ?
                        <>
                            <h1>Спасибо за ваши ответы!</h1>

                            <div id="player"></div>

                        </>
                        :
                        <>
                            <h1>Расчет результатов</h1>
                            <div className="quiz-progressbar">
                                <AnimatedProgressProvider
                                    valueStart={0}
                                    valueEnd={100}
                                    duration={5}
                                    onEnd={() => { this.setState({ showResults: true }) }}
                                >
                                    {value => {
                                        const roundedValue = Math.round(value);
                                        return (
                                            <CircularProgressbar
                                                value={value}
                                                text={`${roundedValue}%`}
                                                styles={buildStyles({
                                                    pathTransition: "none",
                                                    textColor: "#0cd130",
                                                    pathColor: "#0cd130",
                                                    trailColor: "#eaeef2"
                                                })}
                                            />
                                        );
                                    }}
                                </AnimatedProgressProvider>
                            </div>
                        </>
                    }
                </div>
            )
        }

        const currentQuestion = this.state.questions[currentQuestionId];

        const progresbarWidth = 100 * (currentQuestionId + 1) / (this.state.questions.length)

        return (
            <div className="quiz">
                <div className="quiz-left-container">
                    <img className="quiz-image" src={`images/${this.props.gender}_${currentQuestionId + 1}.png`} alt="" />
                </div>
                <div className="quiz-right-container">
                    <div className="quiz-container">
                        <div className="quiz-indicator">
                            <div className="quiz-indicator_bar">
                                <div className="quiz-indicator_bar--Filled" style={{ width: progresbarWidth + '%' }} />
                            </div>
                        </div>

                        <div className="quiz-progress">
                            <svg onClick={this.handleGoBack} width={24} height={24} viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z" /></svg>
                            <span className="quiz-progress-numbers">
                                <span className="quiz-progress-number--Selected">{currentQuestionId + 1}</span><span>/</span>{this.state.questions.length}
                            </span>
                        </div>

                        {currentQuestion.answers.length ?
                            <SelectQuestion {...currentQuestion} handleSelectAnswer={(selectedAnswer) => this.handleSelectAnswer(currentQuestionId, selectedAnswer)} /> :
                            <InputQuestion title={currentQuestion.title} input={currentQuestion.input} handleSubmitAnswer={(input) => this.handleInputAnswer(currentQuestionId, input)} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Quiz;